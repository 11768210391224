.about-careers-container {
    position: relative;
    height: auto; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #0d0d0d;
    padding-top: 2%;
    
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    padding-bottom: 15vh;
   
}

.about-careers-content{
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 45vw;
    color: white;
}

.about-careers-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 45vw;
    align-items: center;
    text-align: left;
    padding-top: 2%;
}
.about-careers-main-heading{
   font-family: 'Playfair Display', serif;
   font-size: 3rem;
   max-width: 90%;

}

.about-careers-heading{
    font-size: 1.5rem;
    margin-bottom: 2%;
 }

.about-careers-description{
    margin-top: 2%;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 150%;
    padding-right: 10%;
    color: #ffffff;
    text-align: justify;
}

.description2{
    margin-top: 5%;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 150%;
    padding-right: 10%;
    color: #ffffff;
}

.description3{
    margin-top: 5%;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 150%;
    /* padding-right: 10%; */
    color: #ffffff;
    text-align: justify;
}

.description4{
    margin-top: 8%;
    font-size: 1.5rem;
    padding-right: 10%;
    font-weight: bold;
    /* color: #ffffff; */
}

.about-careers-hr{
    border: none;
    border-top: 2px solid #310617; /* Red color for the line */
    width: 30%;                /* 20% of the container's width */
    margin-left: 15% !important;          
    margin-top: 5%;
    margin-bottom: 2%;
    transform: translateX(-50%); 
}

.about-careers-image {
    max-width: 100%; 
    height: 70%; /* Maintain the image's aspect ratio */
    display: block;
  }

  .about-careers-description3{
    font-size: 1.2rem;
    max-width: 80%;
    text-align: center;
    margin-top: 10%;
    color: #ffffff;
  }

  .career-email{
    /* font-size: 2rem; */
    color: #d61525;
    font-weight: bold;
    margin-top: 3%;
  }
  @media (max-width: 599px) {
    .about-careers-container{
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .about-careers-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 90vw;
        align-items: center;
        padding-top: 10vh;
        
    }
    .about-careers-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 90vw;
        align-items: center;
        text-align: center;
        padding-top: 2%;
    }
    .about-careers-main-heading{
       font-family: 'Playfair Display', serif;
       font-size: 2rem;
       max-width: 100%;
       margin-bottom: 5vh;
       text-align: center;
    
    }
    .about-careers-heading{
        font-size: 0.8rem;
        margin-bottom: 3vh; 
     }
     .about-careers-description{
        margin-top: 5vh;
        font-size: 1rem;
        text-align: center;
        width: 90%;
        padding-right: 0%;
    }
    .about-careers-image {
        max-width: 100%; 
        height: auto; /* Maintain the image's aspect ratio */
        display: block;
        margin-top: 5vh;
      }
    
  .about-careers-description3{
    font-size: 1rem;
    max-width: 90%;
    text-align: center;
    margin-top: 10%;
  }
  }