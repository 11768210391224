.overview-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: visible;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    margin-bottom: 20vh;
}

.overview-heading {
    font-family: 'Playfair Display', serif;
    font-size: 8vh;
    margin-bottom: 8%;
    text-align: right;
    margin-right: 10%;
}

.text-highlight {
    color: #d61525;
}

.overview-image, .overview-image-mob {
    max-width: 100%;
    height: auto;
    display: none; /* Both images are initially set to not display */
    margin: 0 auto;
}

@media (min-width: 600px) {
    .overview-image {
        display: block; /* Display the desktop image on screens wider than 600px */
    }
    .overview-image-mob {
        display: none; /* Ensure the mobile image is hidden on wider screens */
    }
}

@media (max-width: 599px) {
    .overview-container {
        margin-bottom: 5vh;
        height: auto;
    }
    .overview-heading {
        font-size: 2rem; /* Adjust font size for smaller screens */
        text-align: center; /* Optional: Change alignment to center for smaller screens */
        margin-right: 5%; /* Adjust margin for smaller screens */
    }
    .overview-image {
        display: none; /* Hide the desktop image on smaller screens */
    }
    .overview-image-mob {
        display: block; /* Display the mobile image on screens 599px wide or less */
    }
}
