

.services-container {
    text-align: center;
    background-color: #ffffff;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    padding-top: 1%;
    font-family: 'montserrat', serif;
}

.services-title {
    font-family: 'Playfair Display', serif;
    font-size: 7vh;
    font-weight: bold;
    color: #000000;
}

.services-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5vh 6vw; /* Gap between cards: 2vh vertically and 2vw horizontally */
    margin-top: 5%;
    padding: 0 5vw; /* Padding on left and right */
}

.service-card {
    width: 25vw; /* Set card width to take up 25% of the viewport width */
    height: 200px; /* Fixed height */
    perspective: 1500px; /* Increased perspective for stronger 3D effect */
    margin-bottom: 2vh; /* Vertical gap between rows */
    transition: transform 0.3s ease-in-out; /* Smooth transition for pop-up effect */
}

.service-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s ease;
    transform-style: preserve-3d;
    transform: scale(1); /* Initial scale */
}

.service-card.flipped .service-card-inner {
    transform: rotateY(180deg) scale(1.05); /* Rotate and slightly scale up to give pop-up effect */
}

.service-card-front,
.service-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Front side of the card (Service Name) */
.service-card-front {
    font-size: 3vh; /* Larger font size for the service name */
    color: white; /* White text color */ /* Make it bold */
    font-weight: bolder;
}

/* Back side of the card (Description) */
.service-card-back {
    transform: rotateY(180deg);
    font-size: 2vh; /* Smaller font size for description */
    line-height: 1.4;
    color: #ffffff; /* Black text color for the description */
    text-align: center; /* Center-align the text */
    background-image: url('/public/cardBack.png');
    background-size: cover;
    background-position: center;
    font-weight: bold;
    
}

/* Individual backgrounds for each service card */
.red-team-bg {
    background-image: url('/public/redTeamBg.png');
    background-size: cover;
    background-position: center;
}

.phishing-bg {
    background-image: url('/public/phishingBg.png');
    background-size: cover;
    background-position: center;
}

.vapt-bg {
    background-image: url('/public/vaptBg.png');
    background-size: cover;
    background-position: center;
}

.soc-bg{
    background-image: url('/public/socBg.png');
    background-size: cover;
    background-position: center;
}

.infra-bg{
    background-image: url('/public/infraBg.png');
    background-size: cover;
    background-position: center;
}

@media (max-width: 599px) {
    .services-container{
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 15vh;
    }
    .services-title {
        font-family: 'Playfair Display', serif;
        font-size: 2.5rem; /* Adjust title font size for smaller screens */
    }

    .services-grid {
        flex-direction: column; /* Stack flex items vertically */
        align-items: center;
        gap: 2vh; /* Adjust gap for vertical stacking */
        padding: 0 0vw;
    }

    .service-card {
        width: 80vw; /* Take up 90% of the viewport width */
        height: 40vh; /* Adjust height to be automatic */
        margin: 2vh 0; 
        margin-left: 0vh;
        margin-left: -5%;
    }

    .service-card-front {
        font-size: 1.3rem; /* Larger font size for the service name */
        color: white; /* White text color */ /* Make it bold */
        font-weight: bolder;
    }
}