.footer-container {
    height: 45vh; /* Setting the height of the footer */
    background-color: #0D0D0D; /* Dark background for the footer */
    color: #fff; /* White text color */
    display: flex; /* Using flexbox to align children */
    align-items: center; /* Centering content vertically */
    justify-content: center; /* Centering content horizontally */
    border-top: 1px solid rgb(157, 157, 157); /* Adding a white top border */
    padding-top: 5vh;
    flex-direction: column;
  }

  .company-content{
    height: 20vh;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company-logo{
    max-width: 40%; 
    height: 15%; /* Maintain the image's aspect ratio */
    display: block;
    margin-bottom: 1vh;
  }
  
  .slogan{
    font-weight: bold;
    margin-bottom: 2vh;
  }

  .footer-descripton{
    font-size: 0.8rem;
    text-align: center;
  }

  .footer-links {
    margin-top: 7vh;
    display: flex; /* Displaying links in a row */
    flex-wrap: wrap; /* Allowing the list to wrap */
    gap: 20px; /* Space between links */
  }
  
  .footer-links a {
    color: #fff; /* Ensuring links are white */
    text-decoration: none; /* Removing underline from links */
    font-size: 16px; /* Setting a comfortable reading size */
  }
  
  .footer-links a:hover {
    text-decoration: underline; /* Underline on hover for better UX */
  }
  
  .copyright-footer{
    margin-top: 10vh;
    font-size: 0.8rem;
  }

  @media (max-width: 599px) {
    .footer-container{
        justify-content: left;
        align-items: start;
        padding-left: 5vw;
        height: auto;
    }

    .footer-descripton{
        font-size: 0.6rem;
        text-align: left;
        max-width: 70%;
      }
    .company-content{
        align-items: flex-start;
        width: 70vw;
    }
    .footer-links{
        display: flex;
        flex-direction: column;
    }
    .copyright-footer{
        margin-bottom: 5vh;
    }
  }