.banner-container {
    position: relative;
    height: 70vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow:visible;
    background-color: #0d0d0d;
    padding-top: 6%;
    padding-bottom: 15vh;
    
}

.banner-area {
    background-image: url(/public/homeBanner.png);
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: 55vh;
    padding-top: 2%;
}

.banner-heading{
    font-family: 'Playfair Display', serif;
    padding-left: 50%;
    font-size: 5vh;
    color: white;
 
 }

 hr {
    border: none;
    border-top: 2px solid #811301; /* Red color for the line */
    width: 10%;                /* 20% of the container's width */
    margin-left: 55%;          /* Padding-left equivalent for center alignment */
    margin-top: 2%;
    margin-bottom: 2%;
    transform: translateX(-50%); /* Adjusts alignment to account for the margin */
}

.banner-content{
  margin-left: 50%;
  max-width: 50%;
  margin-right: 10%;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 2.5vh;
  font-weight: lighter;
  text-align: justify;
}

.home-banner-Btn{
    margin-left: 50% !important;
    background-color: #d61525 !important;
    max-width: fit-content !important;
    margin-top: 2% !important;
}
  

@media (max-width: 599px) {
    .banner-container{
        height: auto;

    }
    .banner-area {
        background-image: none; /* Removes the background image on smaller screens */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .banner-heading{
        padding-left: 0%;
        font-size: 2.5rem;
        text-align: center;
    }
    .banner-content{
        margin-left: 5%;
        max-width: 80%;
        font-size: 1rem;
        margin-top: 10%;
        text-align: center;
      }
      .banner-Btn{
        margin-left: 5% !important;
        margin-top: 10% !important;
    }
    hr {
        width: 40%;                /* 20% of the container's width */
        margin-left: 50%;          /* Padding-left equivalent for center alignment */
    }
    .home-banner-Btn{
        margin-left: 0% !important;
        background-color: #d61525 !important;
        max-width: fit-content !important;
        margin-top: 10vh !important;
        font-size: 0.7rem !important;
    }
}