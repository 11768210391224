.vapt-features-container {
    position: relative;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow:visible;
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: column;
    padding-top: 8%;
    align-items: center;
}
.vapt-features-image {
    max-width: 100%; /* Ensure the image fits within its container */
    height: auto; /* Maintain the image's aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image horizontally if needed */
  }
  
  @media (min-width: 600px) {
    .vapt-features-image {
        display: block; /* Display the desktop image on screens wider than 600px */
    }
    .vapt-features-image-mob {
        display: none; /* Ensure the mobile image is hidden on wider screens */
    }
}

  @media (max-width: 599px) {
   .vapt-features-container{
    margin-top: 15vh;
    height: auto
   }
   .vapt-features-image {
    display: none; /* Hide the desktop image on smaller screens */
}
.vapt-features-image-mob {
    display: block; /* Display the mobile image on screens 599px wide or less */
    max-width: 100%; /* Ensure the image fits within its container */
    display: block;
    margin: 0 auto; /* Center the image horizontally if needed */
    height: auto;
}
  }