.home-container {
  position: relative;
  height: 100vh; /* Ensure the container is always full viewport height */
  overflow: hidden;
  min-height: auto;
  width: 100vw;
  background-image: url(../../../public/LandingBg.png);
  background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
}

.desktop-video {
  display: block; /* Show desktop video by default */
}

.mobile-video {
  display: none; /* Hide mobile video by default */
}

.content {
  position: relative;
  z-index: 10; /* Ensures content is above the video */
  color: white;
  text-align: left;
  padding-top: 10%;
  padding-left: 10%;
  max-width: 60%;
  margin-right: 10%;
  box-sizing: border-box;
}

.heading{
  font-size: 4.5rem;
  font-family: 'Playfair Display', serif;
}

.description{
  font-size: 1.2rem;
  font-weight: normal;
  text-align: justify;
  max-width: 80%;
}

.subHeading{
  font-weight: bold;
  font-size: 2vh;
  margin-top: 8%;
}

.ctaBtn{
  background-color: #d61525 !important;
}

/* Media query for screens smaller than 600px */
@media (max-width: 599px) {
  .home-container {
    position: relative;
    overflow: hidden;
    height: auto;
    padding-bottom: 5vh;
    background-image: url(../../../public/HomeLandingBg.png);
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
  }
  .heading{
    font-size: 2.5rem;
    text-align: center;
  }
  .content{
    display: flex;
    flex-direction: column;
    max-width: 90%;
    justify-content: center;
    align-items: center;
  }
  .description{
    font-size: 1rem;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 5vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
  .subHeading{
    display: none ;
  }
  .mobile-video {
    display: block; /* Show mobile video */
    position: absolute;
    top: 0; /* Reset top position */
    left: 0; /* Reset left position */
  }
}
