.landing-contact-container {
    position: relative;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.landing-contact-container::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 65vw; /* 50% of the viewport width */
    height: 100%; /* Full height */
    background-image: url('/public/contactUsBg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1; /* Ensures the image stays behind the content */
}

.landing-contact-content{
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 60vw;
    color: white;
}

.landing-contact-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 30vw;
    align-items: center;
}
.landing-contact-heading{
   font-family: 'Playfair Display', serif;
   font-size: 5vh;
   max-width: 90%;

}

.landing-contact-description1{
    margin-top: 5%;
    font-size: 1rem;
    padding-right: 10%;
    text-align: justify;
}
.landing-contact-description{
    margin-top: 2%;
    font-size: 1rem;
    padding-right: 10%;
    text-align: justify;
}

.landing-contact-description2{
    margin-top: 8%;
    font-size: 1.2rem;
    padding-right: 10%;
    font-weight: bold;
    text-align: justify;
}

.landing-contact-hr{
    border: none;
    border-top: 2px solid #310617; /* Red color for the line */
    width: 30%;                /* 20% of the container's width */
    margin-left: 15% !important;          
    margin-top: 5%;
    margin-bottom: 2%;
    transform: translateX(-50%); 
}

.landing-contact-image {
    max-width: 100%; 
    height: 50%; /* Maintain the image's aspect ratio */
    display: block;
  }

  .landing-contact-description3{
    font-size: 1.2rem;
    max-width: 80%;
    text-align: center;
    margin-top: 10%;
  }

  .email{
    font-size: 2rem;
    color: #d61525;
    font-weight: bold;
    margin-top: 3%;
  }
  
  @media (max-width: 599px) {
.landing-contact-container{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100vw;

}
.landing-contact-container::before{
    width: 100vw;
}
.landing-contact-content{
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 90vw;
    color: white;
    padding-left: 5vw;
}

.landing-contact-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
    align-items: center;
   
}
.landing-contact-heading{
   font-family: 'Playfair Display', serif;
   font-size: 1.8rem;
   max-width: 90%;
   padding-top: 10vh;

}

.landing-contact-description1{
    margin-top: 5%;
    font-size: 0.8rem;
    padding-right: 10%;
    text-align: left;
    margin-bottom: 2vh;
}
.landing-contact-description{
    margin-top: 2%;
    font-size: 0.8rem;
    padding-right: 10%;
    text-align: left;
    margin-bottom: 2vh;
}

.landing-contact-description2{
    margin-top: 8%;
    font-size: 0.8rem;
    padding-right: 10%;
    font-weight: bold;
    text-align: justify;
}
.landing-contact-image {
    margin-top: 5vh;
    max-width: 70%; 
    height: auto; /* Maintain the image's aspect ratio */
    display: block;
  }
  .landing-contact-description3{
    font-size: 0.9rem;
    max-width: 90%;
    text-align: center;
    margin-top: 10%;
    color: #ffffff;
  }
  .email{
    font-size: 1rem;
    color: aliceblue;
    margin-bottom: 10vh;
  }

  }