.banner-rt-container {
    position: relative;
    overflow:visible;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
    
}

.banner-rt-area {
    background-image: url(/public/redTeamBanner.png);
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40vh;
    padding-top: 5%;
}

.banner-rt-heading{
    font-family: 'Playfair Display', serif;
    font-size: 5vh;
    color: white;
    text-align: center;
 
 }

 .rt-banner-hr {
    border: none;
    border-top: 2px solid #c61525; /* Red color for the line */
    width: 10%;                /* 20% of the container's width */
    margin-left: 50% !important;       
    margin-top: 2%;
    margin-bottom: 2%;
    transform: translateX(-50%); /* Adjusts alignment to account for the margin */
}

.banner-rt-content{
  max-width: 70%;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 2vh;
  text-align: center;
}

.banner-rt-Btn{
    background-color: #d61525 !important;
    max-width: fit-content !important;
    margin-top: 2% !important;
}
  
@media (max-width: 599px) {
    .banner-rt-container{
        margin-top: 10vh;
        height: 70vh;
        margin-bottom: 10vh;
    }
    .banner-rt-area {
        background-image: none;
        width: 90%;
        padding-left: 5%;
        align-items: center;
    }
    .rt-banner-hr {
        border: none;
        border-top: 2px solid #c61525; /* Red color for the line */
        width: 45%;                /* 20% of the container's width */
        margin-left: 50% !important;       
        margin-top: 5%;
        margin-bottom: 2%;
        transform: translateX(-50%); /* Adjusts alignment to account for the margin */
    }

    .banner-rt-content{
        max-width: 100%;
        box-sizing: border-box;
        color: #000000;
        font-size: 1rem;
        text-align: center;
        margin-top: 5vh;
        height: auto;
      }
    .banner-rt-heading{
        font-size: 2rem;
        color: #000000;
        text-align: center;
    }
    .banner-rt-Btn{
        margin-top: 5vh !important;
        margin-bottom: 10vh !important;
    }
}