.features-phishing-container {
    position: relative;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow:visible;
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    align-items: center;
}

.features-phishing-heading{
   font-family: 'Playfair Display', serif;
   font-size: 5vh;
  text-align: center;
  margin-bottom: 5% !important;
  max-width: 40%;
}

.features-phishing-image {
    max-width: 100%; /* Ensure the image fits within its container */
    height: auto; /* Maintain the image's aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image horizontally if needed */
  }
  
  @media (min-width: 600px) {
    .features-phishing-image {
        display: block; /* Display the desktop image on screens wider than 600px */
    }
    .features-phishing-image-mob {
        display: none; /* Ensure the mobile image is hidden on wider screens */
    }
}

  @media (max-width: 599px) {
    .features-phishing-heading{
      font-family: 'Playfair Display', serif;
      font-size: 2rem;
     text-align: center;
     margin-bottom: 5vh;
     max-width: 90%;
   }
   .features-phishing-container{
    margin-top: 10vh;
    height: auto;
   }
   .features-phishing-image {
    display: none; /* Hide the desktop image on smaller screens */
}
.features-phishing-image-mob {
    display: block; /* Display the mobile image on screens 599px wide or less */
    max-width: 100%; /* Ensure the image fits within its container */
    height: auto; /* Maintain the image's aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image horizontally if needed */
}
  }
