.banner-infra-container {
    position: relative;
    overflow:visible;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
    
}

.banner-infra-area {
    background-image: url(/public/infraBanner.png);
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: column;
   
    align-items: center;
    height: 40vh;
    padding-top: 5%;
}

.banner-infra-heading{
    font-family: 'Playfair Display', serif;
  
    font-size: 5vh;
    color: white;
    text-align: center;
 
 }

 .infra-hr {
    border: none;
    border-top: 2px solid #c61525; /* Red color for the line */
    width: 10%;                /* 20% of the container's width */
    margin-left: 50% !important;       
    margin-top: 2%;
    margin-bottom: 2%;
    transform: translateX(-50%); /* Adjusts alignment to account for the margin */
}

.banner-infra-content{

  max-width: 70%;
 
  box-sizing: border-box;
  color: #ffffff;
  font-size: 2vh;
  text-align: center;
}

.banner-infra-Btn{
    
    background-color: #d61525 !important;
    max-width: fit-content !important;
    margin-top: 2% !important;
}
  

@media (max-width: 599px) {
    .banner-infra-container{
        margin-top: 10vh;
        height: 70vh;
    }
    .banner-infra-area {
        background-image: none;
        width: 90%;
        padding-left: 5%;
        align-items: center;
    }
    .infra-hr {
        border: none;
        border-top: 2px solid #c61525; /* Red color for the line */
        width: 40%;                /* 20% of the container's width */
        margin-left: 50% !important;       
        margin-top: 2%;
        margin-bottom: 2%;
        transform: translateX(-50%); /* Adjusts alignment to account for the margin */
    }

    .banner-infra-content{
        max-width: 90%;
        box-sizing: border-box;
        color: #000000;
        font-size: 0.8rem;
        text-align: center;
        margin-top: 5vh;
      }
    .banner-infra-heading{
        font-size: 2rem;
        color: #000000;
        text-align: center;
    }
    .banner-infra-Btn{
        margin-top: 5vh !important;
    }
}