.about-mission-container {
    position: relative;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow:visible;
   
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: column;
   
    align-items: center;
   
}

.about-mission-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100vw;
    height: 60vh;
    margin-bottom: 10%;
}

.about-mission-heading{
   font-family: 'Playfair Display', serif;
   font-size: 3rem;
   
   text-align: left;
   max-width: 30%;
   
   padding-left: 5%;
}

.description-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.about-mission-description{
    
    font-size: 1.5rem;
   
    line-height: 150%;
    max-width: 40%;
    text-align: justify;
    margin-bottom: 5%;
}

.about-mission-image {
    max-width: 60%; 
    height: 100%; /* Maintain the image's aspect ratio */
    display: block;
  }
  

  @media (max-width: 599px) {
    .about-mission-container{
        padding-top: 5vh;
        height: auto;
    }
    .about-mission-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about-mission-heading{
        font-family: 'Playfair Display', serif;
        font-size: 2rem;
        text-align: center;
        max-width: 90%;
        margin-bottom: 5vh;
        padding-left: 0%;
     }
     .about-mission-image {
        max-width: 80%; 
       height: auto;
        display: block;
      }
    .description-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .about-mission-description{
        font-size: 1rem;
        line-height: 130%;
        max-width: 80%;
        text-align: justify;
        margin-bottom: 10%;
    }
  }
  