.brief-phishing-container {
    position: relative;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow:visible;
    /* background-image: url(/public/redTeambrief-phishingBg.png); */
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* padding-top: 5%; */
}

.brief-phishing-content{
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 40%;
}

.brief-phishing-heading{
   font-family: 'Playfair Display', serif;
   font-size: 6vh;
   /* margin-top: 2%; */

}

.brief-phishing-description{
    margin-top: 10%;
    font-size: 2.5vh;
    text-align: justify;
}

.brief-phishing-hr{
    border: none;
    border-top: 2px solid #c61525; /* Red color for the line */
    width: 30%;                /* 20% of the container's width */
    margin-left: 15% !important;          
    margin-top: 5%;
    transform: translateX(-50%); 
}

.brief-phishing-image {
    max-width: 50%; 
    height: 70%; /* Maintain the image's aspect ratio */
    display: block;
  }
  


  @media (max-width: 599px) {
    .brief-phishing-container{
        display: flex;
        flex-direction: column;
        margin-top: 10vh;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .brief-phishing-content{
        max-width: 90%;
    }
    .brief-phishing-heading{
        font-size: 2rem;
        text-align: center;

    }
    .brief-phishing-description{
        font-size: 0.8rem;
        text-align: center;
    }
    .brief-phishing-image{
        height: auto;
        max-width: 80%;
        margin-top: 5vh;
    }
    .brief-phishing-hr{
        border: none;
        border-top: 2px solid #c61525; /* Red color for the line */
        width: 45%;                /* 20% of the container's width */
        margin-left: 50% !important;          
        margin-top: 5%;
        transform: translateX(-50%); 
    }
  }
