.infra-landing-container {
    position: relative;
    height: 65vh; /* Full viewport height */
    width: auto; /* Full viewport width */
    overflow:visible;
    background-image: url(/public/serviceBg.png);
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    padding: 3%;
    display: flex;
    justify-content: center;
}

.infra-landing-heading{
   font-family: 'Playfair Display', serif;
   font-size: 6vh;

}

.infra-landing-image {
    max-width: 100%; /* Ensure the image fits within its container */
    height: auto; /* Maintain the image's aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image horizontally if needed */
  }
  
  .infra-landing-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 12%;
    color: #ffffff;
  }

  .infra-landing-description{
    text-align: center;
    margin-bottom: 5%;
  }

  @media (max-width: 599px) {
    .infra-landing-container{
        background-image: url(/public/serviceBgMob.png); /* Change background image for mobile */
        height: 90vh;
    }
    .infra-landing-heading{
      font-family: 'Playfair Display', serif;
      font-size: 2.5rem;
      text-align: center;
   
   }

   .infra-landing-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content:center ;
    color: #ffffff;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 12%;
  }
  }