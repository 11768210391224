.whyUs-container {
    position: relative;
    height: 90vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow:visible;
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: flex;
    flex-direction: column;
    padding-top: 4%;
    background-color: #0d0d0d;
    padding-bottom: 15vh;
}

.whyUs-heading{
   font-family: 'Playfair Display', serif;
   padding-left: 5%;
   font-size: 7vh;
   color: #ffffff;
   margin-bottom: 2%;

}

  .whyUs-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 80%;
  }

  .whyUs-image {
    max-width: 35%; /* Ensure the image fits within its container */
    height: auto; /* Maintain the image's aspect ratio */
    display: block;
  }
  
  .pointer-container{
    width: 30%;
    height: 75%;
    background-color: rgba(213, 21, 36, 0.6);
    background-image: url(../../../public/WhyUsBg.png);
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    /* border-radius: 5%; */
    color: #ffffff;
    padding: 3%;
    font-weight: lighter;
    text-align: justify;
    display: flex;
      align-items: center;
      justify-content: center;
  }

  @media (min-width: 600px) {
    .whyUs-image {
        display: block; /* Display the desktop image on screens wider than 600px */
    }
    .whyUs-image-mob {
        display: none; /* Ensure the mobile image is hidden on wider screens */
    }
}


  @media (max-width: 599px) {
    .whyUs-container{
      padding-top: 10vh;
      height: auto;
    }
    .whyUs-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .whyUs-heading{
      font-size: 2rem;
      text-align: center;
      padding-left: 0%;
      margin-bottom: 10vh;
    
    }

    .whyUs-image {
      display: none;
    }

    .pointer-container{
      width: 80%;
      font-size: 0.8rem;
      padding-right: 10%;
      display: flex;
      align-items: center;
      height: auto;
    }
    .whyUs-image-mob {
      display: block; /* Display the mobile image on screens 599px wide or less */
      max-width: 100%; /* Ensure the image fits within its container */
  }
  }