.stats-soc-container {
    position: relative;
    height: 80vh; /* Full viewport height */
    overflow:visible;
    display: flex;
    flex-direction: row;
    /* padding-left: 5%; */
    align-items: center;
    justify-content: space-around;
}

.stats-soc-left{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 40%;
}

.stats-soc-heading{
   font-family: 'Playfair Display', serif;
    font-size: 3rem;
  margin-bottom: 5%;
}

.stats-soc-standout{
    color: #d61525;
    margin-bottom: 5%;
}

.stats-soc-description{
    font-size: 2.5vh;
    font-weight: lighter;
    text-align: justify;
}

.stats-soc-content{
   max-width: 50%;
}

.stats-soc-image {
    height: 55vh; 
  }
  
  @media (max-width: 599px) {
    .stats-soc-heading{
        font-family: 'Playfair Display', serif;
        font-size: 2rem;
       margin-bottom: 5%;
       margin-top: 5vh;
       text-align: center;
     }  
     .stats-soc-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
     } 
     .stats-soc-left{
        width: 90%;
     }  
     .stats-soc-image {
        height: 35vh; 
      }
      .stats-soc-content{
        max-width: 90%;
        margin-top: 5vh;
     }
     .stats-soc-description{
        font-size: 0.8rem;
        font-weight: normal;
        text-align: center;
    }
  }