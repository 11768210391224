.nav-company-logo{
    max-width: 30%; 
    height: 5vh; 
    display: block;
    margin-left: 2vw;
}

@media (max-width: 599px) {
    .nav-company-logo{
        max-width: 50%; 
        height: 4vh;
        margin-left: 5vw;
    }
}